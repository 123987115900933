<template>
    <div class="business">
        <!--招商动态-->
        <div class="b-news w-1400">
            <div class="b-h2 clearfix">
                <div class="b-title fl"><i></i> 招商动态</div>
                <div class="more fr"><router-link to="/List?infoType=257023242199109&type=招商动态"> 更多招商动态<i class="el-icon-arrow-right"></i></router-link></div>
            </div>
            <div class="news-main mt-20">
                <el-row :gutter="30">
                    <el-col :span="12">
                        <el-carousel trigger="click" height="520px" :interval="6000" class="news-banner" @change="calChange">
                            <el-carousel-item  v-for="(v,i) in ZSDTdata" :key="i+'dt'">
                                <div class="news-main-l" @click="goDetail(v.id,'招商动态',ZSDTparams)">
                                    <el-image :src="baseUrl+v.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                </div> 
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                    <el-col :span="12">
                        <div class="news-main-r">
                            <h4>招商动态</h4>
                            <ul>
                                <li class="news-list ellipsis" v-for="(item,k) in ZSDTdata" :key="item.id"><i :class="{act:k===calIndex}"></i><span style="margin-left:18px" @click="goDetail(item.id,'招商动态',ZSDTparams)">{{item.title}}</span></li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--招商环境-->
        <div class="place w-1400">
            <div class="b-h2 clearfix">
                <div class="b-title fl"><i></i> 招商环境</div>
                <div class="more fr"><router-link :to="`/List?infoType=257027635433541&type=招商环境`"> 更多招商环境<i class="el-icon-arrow-right"></i></router-link></div>
            </div>
            <div>
                <div class="cal-small">
                    <ul class="cal-small-list clearfix" :class="{istrans}" :style="`width:${408*6}px;transform:translateX(${transNum}px)`">
                        <li class="cal-smal-item fl" v-for="(item,index) in calList" :key="index +'cal'" @click="goDetail(item.id,'招商环境',ZSHJparams1)">
                            <el-image :src="baseUrl+item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                            <div class="ceng" v-show="!(Math.abs(transNum/408)+1 === index)"></div>
                        </li>
                    </ul>
                    <div class="cal-btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
                    <div class="cal-btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
            <div class="mt-20 clearfix">
                <!-- <el-row :gutter="15">
                    <el-col :span="12">
                        <div class="place-pic">
                            <el-image :src="require('../../../public/img/xnw/place2.png')" style="width:100%;height:100%" fit="cover"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="12">
                          
                    </el-col>
                </el-row> -->
                <div class="dt-left fl">
                    <div class="dt-box" v-for="(item,k) in ZSHJdata2" :key="k + 'hj'" :class="{act:k == isact}" @mouseenter="isact=k" @click="goDetail(item.id,'招商环境',ZSHJparams2)">
                        <div class="place-pic">
                            <el-image :src="baseUrl+item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                        </div>
                        <div class="dt-ceng">
                            <div class="dt-lr">{{item.title}}</div> 
                        </div>
                    </div>    
                </div>
                <div class="dt-right fr">
                    <div class="place-list" v-for="(item,k) in ZSHJdata2 " :key="item.id" :class="{lact:k == isact}">
                        <router-link :to="`/Detail?id=${item.id}&infoType=257027635433541&type=招商环境&oParams:JSON.stringify(oParams)`">
                            <h5 class="ellipsis"><i class="iconfont icon-fuzhushuxian"></i>{{item.title}}</h5>
                            <div class="place-list-detail">{{regHtml(item.content)}}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--办事指南-->
        <!-- <div class="flowpath w-1400">
            <div class="b-h2 clearfix">
                <div class="b-title fl"><i></i> 办事指南</div>
            </div>
            <div class="fp-main mt-20">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="外商投资" name="zn-1">
                        <div class="zn-box">
                            <div class="zn-item" v-for="item in waishangtouziData" :key="item.No +'zn-1'">
                                <div class="zn-title">外商投资</div>
                                <div class="zn-index">{{item.No}}</div>
                                <div class="zn-content">
                                    {{item.title}}
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="买地自建" name="zn-2">
                        <div class="zn-box">
                            <div class="zn-item" v-for="item in maidizijianData" :key="item.No +'zn-2'">
                                <div class="zn-title">买地自建</div>
                                <div class="zn-index">{{item.No}}</div>
                                <div class="zn-content">
                                    {{item.title}}
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="厂房租赁" name="zn-3">
                        <div class="zn-box">
                            <div class="zn-item" :class="{others:item.No === '04'}" v-for="item in changfangzulinData" :key="item.No +'zn-3'">
                                <div class="zn-title">厂房租赁</div>
                                <div class="zn-index">{{item.No}}</div>
                                <div class="zn-content" :class="{other:item.No === '04'}">
                                    <div v-if="item.No !== '04'">{{item.title}}</div>
                                    <ul v-else>
                                        <li v-for="v in item.title" :key="v">{{v}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="写字楼租赁" name="zn-4">
                        <div class="zn-box">
                            <div class="zn-item" v-for="item in xiezilouData" :key="item.No +'zn-4'">
                                <div class="zn-title">写字楼租赁</div>
                                <div class="zn-index">{{item.No}}</div>
                                <div class="zn-content">
                                    {{item.title}}
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="公司注册" name="zn-5">
                        <div class="zn-box gsbg"></div>
                    </el-tab-pane>
                    <el-tab-pane label="税收优惠政策" name="zn-6">
                        <div class="zn-box">
                            <div class="zn-item" v-for="item in shuishouzhenceData" :key="item.No +'zn-4'">
                                <div class="zn-title">税收优惠政策</div>
                                <div class="zn-index">{{item.No}}</div>
                                <div class="zn-content">
                                    {{item.title}}
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div> -->
        <!--招商项目-->
        <div class="business-all w-1400">
            <div class="b-h2 clearfix">
                <div class="b-title fl"><i></i> 招商项目</div>
                <!-- <div class="more fr" @click="goList(257023606935621,3,'其他产业')"> 更多<i class="el-icon-arrow-right"></i></div> -->
            </div>
            <div class="mt-20 clearfix">
                <div class="bs-item" v-for="(v,k) in businessList" :key="k+'ps'" :class="{b5:(k+1)%5 === 0}">
                    <el-image :src="require(`../../../public/img/xnw/bs${k+1}.png`)" style="width:100%;height:100%" fit="cover"></el-image>
                    <div class="ceng ceng2" @click="goList(257023606935621,3,v)">{{v}}</div>
                </div>
            </div>
        </div>
        <!-- 政务公开 -->
        <div class="business-all w-1400">
            <div class="b-h2 clearfix">
                <div class="b-title fl"><i></i> 政务公开</div>
                <div class="more fr" @click="$router.push('/List?infoType=455192684163141&type=政务公开')"> 更多<i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="sxzc-mian mt-10">
                <el-row :gutter="30">
                    <el-col :span="14">
                        <div class="sxzc-cal">
                            <el-carousel trigger="click" height="510px"  arrow="never">
                                <el-carousel-item v-for="item in SXZCdata.slice(0,4)" :key="item.id+'cal'">
                                    <router-link :to="`/detail?id=${item.id}&type=政务公开&oParams=${JSON.stringify(SXZCparams)}`">
                                        <el-image style="width:100%;height:100%" :src="item.photoUrls"></el-image>
                                    </router-link>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="sxzc-list">
                            <div class="zc-item ellipsis" v-for="item in SXZCdata" :key="item.id" >
                                <router-link :to="`/detail?id=${item.id}&type=政务公开&oParams=${JSON.stringify(SXZCparams)}`">{{item.title}}</router-link>                                
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import {regHtml} from '@/utils/reg'
import {GetZSDTinfos} from '@/api/xiangningwang'
export default {
    data(){
        return{
            isact:1,
            transNum:0,
            istrans:true,
            actimg:'',
            activeName:'zn-1',
            businessList:['特色农产品加工','乡村产业','农业小镇','生态畜牧业','休闲观光农业','农贸市场和蔬菜基地建设','农业产业化','冷链物流','有机肥加工'],
            waishangtouziData:[
                {No:'01',title:'申领外资企业批准书'},
                {No:'02',title:'办理工商注册登记'},
                {No:'03',title:'办理组织机构代码手续'},
                {No:'04',title:'办理用地手续'},
                {No:'05',title:'税务登记'},
                {No:'06',title:'海关备案手续'},
                {No:'07',title:'办理统计财政管理登记'},
                {No:'08',title:'办理用工登记及社会保险登记'},
            ],
            maidizijianData:[
                {No:'01',title:'招商局预审备案'},
                {No:'02',title:'企业工商注册'},
                {No:'03',title:'项目土地摘牌'},
                {No:'04',title:'发改委立项'},
                {No:'05',title:'核发土地使用证'},
                {No:'06',title:'项目选址规划'},
                {No:'07',title:'建设报批报建'},
                {No:'08',title:'竣工核发房产证'},
            ],
            changfangzulinData:[
                {No:'01',title:'项目签约'},
                {No:'02',title:'名称核准'},
                {No:'03',title:'签约厂房租赁合同'},
                {No:'04',title:['环境影响评价','合理用能审批','外商投资企业备案/审批','安全预评价及安全设施设计','职业病危害预评价及职业病防护设施设计']},
                {No:'05',title:'项目备案'},
                {No:'06',title:'营业执照'},
            ],
            xiezilouData:[
                {No:'01',title:'项目签约'},
                {No:'02',title:'名称核准'},
                {No:'03',title:'营业执照'},
                {No:'04',title:'签署租赁合同物业协议'},
                {No:'05',title:'消防审批'},
                {No:'06',title:'装修入住'},
            ],
            shuishouzhenceData:[
                {No:'01',title:'地区税收优惠政策匹配'},
                {No:'02',title:'本地营商环境评估'},
                {No:'03',title:'选址落户方案定制'},
                {No:'04',title:'园区考察对接'},
                {No:'05',title:'工商注册  银行开户'},
                {No:'06',title:'企业正常开展业务'},
                {No:'07',title:'产业扶持政策落实'},
            ],
            ZSDTdata:[],
            ZSDTparams:{
                currentPage: 1,
                filter: {title: "", infoType: 257023242199109,secondInfoType: "重点推荐"},
                pageSize: 9,
            },
            calList:[],
            ZSHJparams1:{
                currentPage: 1,
                filter: {title: "", infoType: 257027635433541,recommendTypes: "推荐"},
                pageSize: 3,
            },
            ZSHJdata2:[],
            ZSHJparams2:{
                currentPage: 1,
                filter: {title: "", infoType: 257027635433541,recommendTypes: "热门"},
                pageSize: 3,
            },
            calIndex:0,
            SXZCdata:[],
            SXZCparams:{//政务公开
                currentPage: 1,
                filter: {infoType:455192684163141,},
                pageSize: 12,
            },
        }
    },
    methods:{
        next(){
            if(this.transNum === -this.calList.length*408/2){
                this.transNum = 0
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum -= 408
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum -= 408
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        prev(){
            if(this.transNum === 0){
                this.transNum = -this.calList.length*408/2
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum += 408
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum += 408
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        handleClick(){},
        getZSDTinfos(){
            GetZSDTinfos(this.ZSDTparams).then(res=>{
                this.ZSDTdata = res.data.data.list;
            })
        },
        getZSHJ1infos(){
            GetZSDTinfos(this.ZSHJparams1).then(res=>{
                this.calList = res.data.data.list;
                this.calList =this.calList.concat(this.calList)
            })
        },
        getZSHJ2infos(){
            GetZSDTinfos(this.ZSHJparams2).then(res=>{
                this.ZSHJdata2 = res.data.data.list;
            })
        },
        getSXZCdata(){
            GetZSDTinfos(this.SXZCparams).then(res=>{
                this.SXZCdata = res.data.data.list;
            })
        },
        calChange(a){
            this.calIndex=a;
        },
        goDetail(id,type,oParams){
            JSON.stringify
            this.$router.push({path:'/Detail',query:{id,type,oParams:JSON.stringify(oParams) }})
        },
        goList(infoType,type,secondInfoType){
            this.$router.push({path:'/xnwzxList',query:{infoType,type,secondInfoType}})
        }
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
    mounted(){
        this.getZSDTinfos();
        this.getZSHJ1infos();
        this.getZSHJ2infos();
        this.getSXZCdata();
        
    }
}
</script>
<style scoped>
.zc-item a:hover{
    color:#0e6ac1;
    cursor: pointer;
}
.zc-item{
    line-height: 40px;
    font-size: 16px;
    border-bottom:1px dashed #eee;
}
.zc-item a{
    color: #666;    
}
.sxzc-list{
    padding: 15px 20px;
    height: 510px;
    overflow: hidden;
    border: 1px solid #E4E7ED;
    border-radius: 3px;
}
.sxzc-cal >>> .el-carousel__button{
    height: 6px;
    width: 50px;
}
.more:hover{
    cursor: pointer;
    color: #0b6d66;
}
.zn-item:nth-child(1) .zn-title{background: #f05039}
.zn-item:nth-child(2) .zn-title{background: #a22285}
.zn-item:nth-child(3) .zn-title{background: #f7c830}
.zn-item:nth-child(4) .zn-title{background: #86c341}
.zn-item:nth-child(5) .zn-title{background: #3695d2}
.zn-item:nth-child(6) .zn-title{background: #9ac000}
.zn-item:nth-child(7) .zn-title{background: #07bdb2}
.zn-item:nth-child(8) .zn-title{background: #7a39f0}
.zn-item:nth-child(1) .zn-content{border-bottom: 5px solid #f05039}
.zn-item:nth-child(2) .zn-content{border-bottom: 5px solid #a22285}
.zn-item:nth-child(3) .zn-content{border-bottom: 5px solid #f7c830}
.zn-item:nth-child(4) .zn-content{border-bottom: 5px solid #86c341}
.zn-item:nth-child(5) .zn-content{border-bottom: 5px solid #3695d2}
.zn-item:nth-child(6) .zn-content{border-bottom: 5px solid #9ac000}
.zn-item:nth-child(7) .zn-content{border-bottom: 5px solid #07bdb2}
.zn-item:nth-child(8) .zn-content{border-bottom: 5px solid #7a39f0}
/* .zn-item:nth-child(1){box-shadow: 0px 0px 2px #f05039;}
.zn-item:nth-child(2){box-shadow: 0px 0px 2px #a22285;}
.zn-item:nth-child(3){box-shadow: 0px 0px 2px #f7c830;}
.zn-item:nth-child(4){box-shadow: 0px 0px 2px #86c341;}
.zn-item:nth-child(5){box-shadow: 0px 0px 2px #3695d2;}
.zn-item:nth-child(6){box-shadow: 0px 0px 2px #9ac000;}
.zn-item:nth-child(7){box-shadow: 0px 0px 2px #07bdb2;}
.zn-item:nth-child(8){box-shadow: 0px 0px 2px #7a39f0;} */
.lact{
    background: rgba(29, 138, 240, 0.2);
}
.gsbg{
    width: 100%;
    height: 100%;
    background: url(../../assets/img/gongsizhuce.png) no-repeat center center
}
.fp-main >>> .el-tabs__header{
    margin-bottom: 0;
}
.zn-content:not(.other){
    margin: 0 auto;
    height: 308px;
    writing-mode: vertical-lr;
    writing-mode: tb-lr;
    font-size: 20px;
    padding: 0px 20px;
    border-bottom: 5px solid #0169ca
}
.other ul li::before{
    content: '';
    display: inline-block;
    padding: 5px;
    border-radius: 50%;
    background-color: #777;
    margin-right: 10px;
    vertical-align: middle;
}
.other{
    font-size: 20px;
    padding: 0px 20px;
    border-bottom: 5px solid #0169ca;
    height: 308px;
    text-align: left;
    line-height: 40px;
    padding-top: 30px;
}
.zn-index{
    line-height: 50px;
    color: #999;
    font-size: 30px;
    border-bottom: 2px solid #E4E7ED;
    font-family: '幼圆'
}
.zn-title{
    font-size: 18px;
    background: #0169ca;
    color: #fff;
    line-height: 40px
}
.others.zn-item{
    width: auto;
}
.zn-item{
    width: 110px;
    height: 400px;
    box-shadow: 0px 0px 5px #666;
}
.zn-box{
    border: 1px solid #E4E7ED;
    /* border-radius: 5px; */
    border-top: 0;
    min-height: 500px;
    text-align: center;
    padding-top: 50px; 
    display: flex;
    justify-content: space-around;
}
.dt-lr{
    margin: 0 auto;
    height: 100%;
    writing-mode: vertical-lr;
    writing-mode: tb-lr;
    font-size: 20px;
    color: #fff;
    padding-top:30px;
}
.act .dt-ceng{
    opacity: 0;
}
.dt-ceng{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(23,100,206,0.7);
    opacity: 1;
    transition: opacity 0.8s;
}
.dt-box.act{
    width: 600px;
}
.place-pic{
    width: 600px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
.dt-box{
    width: 80px;
    height: 500px;
    position: relative;
    overflow: hidden;
    transition: all 0.8s;
}
.dt-left{
    width: 760px;
    display: flex;
}
.dt-right{
    width: calc(100% - 775px);
}
.news-banner >>> .el-carousel__button{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #0169ca;
}
.ceng{
    height: 228px;
    line-height: 228px;
    position: absolute;
    font-size: 24px;
    color: #fff;
    top: 0;
    left: 0;
    z-index: 10;
    text-align: center;
    cursor: pointer;
}
.bs-item.b5{
    margin-right: 0;
}
.bs-item{
    width: 228px;
    height: 228px;
    float: left;
    border-radius: 10px;
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}
.t-header{
    height: 50px;
    line-height: 50px;
    border: 1px solid #777;
    font-weight: 600;
}
.t-item.act{
    background: #f7ebe5;
    color: #fff;
}
.t-item{
    height: 42px;
    line-height: 42px;
    background: #eee;
    border-bottom: 3px solid #fff;
}
.fp-tabel{
    font-size: 16px;
    text-align: center;
    width: 85%;
    margin: 0 auto;
}
.arrow-right{
    width: 43px;
    height: 36px;
    display: inline-block;
    vertical-align: middle;
    background: url(../../assets/img/right2.png);
    margin:7px 10px;
}
.fp-list .fp-item.act{
    background: #0169ca;
}
.fp-list ul .fp-item{
    color: #fff;
    background: #666;
    padding:0 24px

}
.fp-list ul{
    display: flex;
    padding-left: 30px;
}
.fp-list h5{
    color: #0169ca;
}
.fp-list{
    height: 60px;
    line-height: 60px;
    font-size: 17px;
    display: flex;
    justify-content: center;
}
.place-list-detail{
    text-indent: 30px;
    font-size: 16px;
    color: #666;
    line-height: 26px;
    padding:0 15px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
}
.place-list h5 .iconfont{
    color: #0169ca;
}
.place-list h5{
    font-size: 18px;
    height: 40px;
    line-height: 40px;
}
.place-list {
    border-bottom:1px solid #777 ;
    padding-bottom: 20px; 
}
.istrans{
    transition: transform 1.2s;
}
.prev{
    left: 0;
}
.next{
    right: 0;
}
.cal-btn{
    width: 50px;
    height: 80px;
    line-height: 80px;
    font-size: 50px;
    font: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
}
.cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.ceng2{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
}
.cal-smal-item{
    width: 384px;
    height: 280px;
    overflow: hidden;
    margin-right: 24px;
    position: relative;
    cursor: pointer;
}
.cal-small{
    height: 280px;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.news-list span:hover{
    text-decoration: underline;
    color: #0169ca;
    cursor: pointer;
}
.news-list i.act{
    background: #0169ca;
}
.news-list i{
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #ccc;
    vertical-align: middle;
    border-radius: 50%;
}
.news-list{
    height: 48px;
    line-height: 48px;
    color: #666;
    font-size: 15px;
    border-bottom: 1px dashed #ccc;
}
.news-main-r h4{
    height: 30px;
    line-height: 30px;
    padding-left: 5px;
}
.news-main-r{
    height: 520px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 17px;
    overflow-y: auto;
}
.news-main-l{
    height: 520px;
    width: 100%;
}
.b-title{
    /* vertical-align: middle; */
}
.mt-20{
    margin-top:20px;
}
.b-title i{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../assets/img/right.png) no-repeat center center;
    vertical-align: middle;
}
.b-h2{
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    border-bottom: 1px solid #777;
}
.business{
    font-family: '微软雅黑';
}
</style>